<template>
  <!-- Login -->
  <div class="auth-wrapper auth-v1 px-1">
    <span class="bg" />
    <div class="auth-inner py-2">
      <b-card
        id="page-login"
        border-variant="transparent"
        bg-variant="transparent"
        class="mb-0"
      >
        <b-link class="d-block">
          <div class="d-flex justify-content-center align-items-center mb-1">
            <b-img-lazy
              width="150"
              class="mb-1"
              fluid
              :src="require('@/assets/images/logo/kia-white.png')"
            />
          </div>
        </b-link>

        <div class="mb-1 font-weight-bolder">
          <b-card-title class="mb-1 text-white text-center">
            {{ $t("message.forgot") }}
          </b-card-title>
        </div>
        <b-card-text class="mb-2 text-white">
          {{ $t("message.forgotDescription") }}
        </b-card-text>

        <validation-observer ref="loginForm" #default="{ invalid }">
          <b-form class="auth-login-form mt-2" @submit.prevent="recaptchaForgotPassword">
            <!-- email -->
            <b-form-group>
              <label class="text-white" for="email">
                {{ $t("message.tableHeader.email") }}
              </label>
              <validation-provider
                #default="{ errors }"
                name="Correo electrónico "
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  class="formInput text-white"
                  name="login-email"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('message.email_example')"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <div></div>

                <b-link :to="{ name: 'login' }">
                  <small class="text-white">
                    {{ $t("message.backLogin") }}
                  </small>
                </b-link>
              </div>
            </b-form-group>
            <div class="text-center mt-3">
              <b-button
                variant="light"
                type="submit"
                :disabled="invalid"
                class="font-weight-bolder px-3"
              >
                {{ $t("message.send") }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver , localize} from "vee-validate";
import {
  BCard,
  BButton,
  BForm,
  BFormInput,
  BFormSelect,
  BFormGroup,
  BLink,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  VBTooltip,
  BImgLazy,
  BCardText,
  BCardTitle,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";

import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { verifyUser } from "@core/api/request/login";
import i18n from "@/libs/i18n";
export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    // BSV
    BCard,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BLink,
    BImgLazy,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BCardText,
    BCardTitle,
  },
  mixins: [togglePasswordVisibility],
  created() {
    localize("es");
  },
  mounted() {
    localize("es");
  },
  data() {
    return {
      userEmail: null,
      password: "admin",
      status: "",
      required,
      email,
      sideImg: null,
      selected: "a",
      options: [
        { value: "a", text: "Contract Manager" },
        { value: "b", text: "Digital Assets Manager" },
        { value: "c", text: "Budget Manager" },
      ],
    };
  },
  // kia-white.png
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          // console.log("fffffff");
          verifyUser(this.userEmail)
            .then((response) => {
              // console.log("resp", response);
              this.alertToastExit(
                "success",
                `Se envio un correo para cambiar su contraseña`
              );
              this.userEmail = "";

              // this.$router.push({
              //   name: "reset-password",
              //   params: {
              //     token : "eyJndWlkIjoiNzgxNzY4NjgtMjZhMC00NzY2LWEyZTQtZjZlNmY3NmVjYWJlI"
              //   },
              // });
            })
            .catch((error) => {
              // console.log("error hola soy bata", error);
              this.alertToastError(
                "danger",
                `Este correo no pertenece a ningún usuario`
              );
              // this.$refs.loginForm.setErrors(error.response.data.error)
            });
          // useJwt.login({
          //   email: this.userEmail,
          //   password: this.password,
          // })
          //   .then(response => {
          //     const { userData } = response.data
          //     useJwt.setToken(response.data.accessToken)
          //     useJwt.setRefreshToken(response.data.refreshToken)
          //     localStorage.setItem('userData', JSON.stringify(userData))
          //     this.$ability.update(userData.ability)

          //     // ? This is just for demo purpose as well.
          //     // ? Because we are showing eCommerce app's cart items count in navbar
          //     this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

          //     // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
          //     this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
          //       .then(() => {
          //         this.$toast({
          //           component: ToastificationContent,
          //           position: 'top-right',
          //           props: {
          //             title: `Welcome ${userData.fullName || userData.username}`,
          //             icon: 'CoffeeIcon',
          //             variant: 'success',
          //             text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
          //           },
          //         })
          //       })
          //   })
          //   .catch(error => {
          //     this.$refs.loginForm.setErrors(error.response.data.error)
          //   })
        }
      });
    },
    recaptchaForgotPassword() {
      grecaptcha.ready(() => {
        grecaptcha.execute('6LeJ5MQmAAAAAHY40ZyJb8PBOK9GiZw_RboBAKBc', { action: 'submit' }).then((token) => {
          // console.log(token);
          this.login();
        }).catch(error => console.log(error));
      });
    },

    alertToastError(type = null, title = null, message = null) {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title,
          icon: "XIcon",
          variant: type,
          text: message,
        },
      });
    },
    alertToastExit(type = null, title = null, message = null) {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title,
          icon: "CheckIcon",
          variant: type,
          text: message,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.bg {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: url("../../../assets/images/pages/login/background.png") center
    center;
  background-size: cover;
}
</style>
